// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/xiaopangchetupianjiazaishibai-2.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".UgcVideo .number{color:#00f}.UgcVideo .swiper{position:relative}.UgcVideo .swiper-wrapper-content{width:90%;height:500px;--swiper-pagination-color:#ffcc37;margin:0 auto}.UgcVideo .swiper-wrapper-content .swiper-container{border-radius:6px}.UgcVideo .swiper-wrapper-content .swiper-container .swiper-wrapper{flex-wrap:nowrap}.UgcVideo .swiper-wrapper-content .image-slot{display:flex;justify-content:center;align-items:center;height:100%;font-size:24px;background:#f4f4f4;color:#e6e6e6}.UgcVideo .swiper-wrapper-content .image-slot.image-slot-error .error{width:250px;height:250px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover}.UgcVideo .swiper-wrapper-content .tag-list{position:absolute;left:20px;bottom:20px;z-index:100;display:flex;flex-wrap:wrap;margin:40px 0 0 10px;padding:0}.UgcVideo .swiper-wrapper-content .tag-list li{font-size:22px;height:40px;line-height:40px;background-color:rgba(0,0,0,.5);color:#fff;text-align:center;padding:0 20px;margin-right:20px;margin-bottom:10px}.UgcVideo .video-body{width:100%;background-color:#f5f7fa;border-radius:8px;background-size:cover;overflow:hidden;position:relative}.UgcVideo .video-body:hover .cover{display:flex}.UgcVideo .video-body .image-slot{display:flex;justify-content:center;align-items:center;height:100%}.UgcVideo .video-body .cover{display:none;justify-content:center;align-items:center;cursor:pointer;position:absolute;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,.5);color:#fff;border-radius:8px}.UgcVideo .video-body .cover .video-play{color:#fff;font-size:28px;cursor:pointer}", ""]);
// Exports
module.exports = exports;
