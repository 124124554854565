<template>
  <Layout class="UgcVideo">
    <h4>待审核: <span class="number">{{ listInfo.unCheckNum }}</span> 个</h4>
    <BHeader>
      <el-form
        :model="formData"
        inline
      >
        <el-form-item label="审核状态">
          <el-select v-model="formData.shareStatus">
            <el-option
              v-for="(item, index) in verifyStateLuckList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="用户ID">
          <el-input
            v-model="formData.userIdStr"
            placeholder="用户ID"
            @keyup.enter="handleSearch"
          />
        </el-form-item>
        <el-form-item label="奖期">
          <el-input
            v-model="formData.activityId"
            placeholder="奖期"
            @keyup.enter="handleSearch"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </BHeader>
    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
    >
      <!-- <el-table-column
        prop="No"
        label="编号"
      /> -->
      <el-table-column
        prop="createTime"
        label="上传时间"
        width="160px"
      />
      <el-table-column
        prop="shareDetail"
        label="文字内容"
        min-width="220px"
      />
      <el-table-column
        label="图片"
        width="140"
      >
        <template #default="{row}">
          <div
            class="video-body"
            style="width: 120px; height: 60px"
          >
            <el-image
              style="width: 100%;height: 100%;"
              :src="row.shareImageList.length ? row.shareImageList[0] : ''"
              fit="cover"
            >
              <div
                slot="placeholder"
                class="image-slot"
              >
                <i class="el-icon-loading" />
              </div>
              <div
                slot="error"
                class="image-slot"
              >
                <i class="el-icon-picture-outline" />
              </div>
            </el-image>
            <div class="cover">
              <i
                class="el-icon-orange video-play"
                title="Play"
                @click="handlePlayVideo(row)"
              />
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="userName"
        label="用户昵称"
        min-width="100"
      />

      <el-table-column
        prop="userIdStr"
        label="用户ID"
        width="180"
      />

      <el-table-column
        prop="shareStatus"
        label="状态"
      >
        <template v-slot="{row}">
          {{ verifyStateLuckMap[row.shareStatus] || '未知' }}
        </template>
      </el-table-column>

      <el-table-column
        label="操作"
        width="240px"
        fixed="right"
      >
        <template #default="{row}">
          <template v-if="row.shareStatus === 1 || row.shareStatus === 2">
            <el-button
              :loading="row.loading"
              type="success"
              @click="handleToggleState(row, 3)"
            >
              通过
            </el-button>
            <el-button
              v-if="row.shareStatus !== 2"
              :loading="row.loading"
              @click="handleToggleState(row, 2)"
            >
              不通过
            </el-button>
          </template>
          <template v-else>
            <el-button
              :type="row.shareStatus === 3 || row.shareStatus === 5 ? 'primary': 'danger'"
              :loading="row.loading"
              @click="handleToggleState(row, row.shareStatus === 3 || row.shareStatus === 5 ? 4 : 5)"
            >
              {{ row.shareStatus === 3 || row.shareStatus === 5 ? '上架' : '下架' }}
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryData(formData)"
    />

    <el-dialog
      :visible.sync="playVideo"
      :center="true"
      top="80px"
      width="1000px"
    >
      <section
        :key="currentRows.id"
        class="swiper-wrapper-content"
      >
        <swiper
          class="swiper"
          :options="swiperOption"
        >
          <swiper-slide
            v-for="item in currentRows.shareImageList || []"
            :key="item"
          >
            <el-image
              style="width: 100%; height: 500px;"
              :src="item"
              fit="cover"
            >
              <div
                slot="placeholder"
                class="image-slot image-slot-loading"
              >
                loading<span class="dot">...</span>
              </div>
              <div
                slot="error"
                class="image-slot image-slot-error"
              >
                <div class="error" />
              </div>
            </el-image>
          </swiper-slide>
          <div
            slot="pagination"
            class="swiper-pagination"
          />
          <div
            slot="button-prev"
            class="swiper-button-prev"
          />
          <div
            slot="button-next"
            class="swiper-button-next"
          />
        </swiper>
      </section>
    </el-dialog>
  </Layout>
</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css'
import {
  getOneDollarAllShare,
  updateOneDollarWinningShareStatus
} from '@/api/LuckyBuy.js'

export default {
  name: 'UgcVideo',
  components: { Swiper, SwiperSlide },
  data () {
    return {
      verifyStateLuckList: [
        { name: '待审核', value: 1 },
        { name: '不通过', value: 2 },
        { name: '通过', value: 3 },
        { name: '上架', value: 4 },
        { name: '下架', value: 5 }
      ],
      verifyStateLuckMap: {
        1: '待审核',
        2: '不通过',
        3: '通过',
        4: '上架',
        5: '下架'
      },
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        loading: false,
        total: 0,
        unCheckNum: 0
      },
      playVideo: false,
      currentRows: {},

      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        loop: false
      }
    }
  },
  created () {
    this.handleSearch()
  },
  methods: {
    parserURL (url) {
      if (!url) return url
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = 'https://' + url
      }
      return url
    },
    handleSearch () {
      this.formData.page = 1
      this.queryData()
    },
    queryData () {
      this.listInfo.loading = true
      getOneDollarAllShare(this.formData)
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total
            this.listInfo.unCheckNum = res.data.pendingCount
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    handleToggleState (row, shareStatus) {
      this.$set(row, 'loading', true)
      updateOneDollarWinningShareStatus({ id: row.id, shareStatus })
        .then(res => {
          if (res.code === 200) {
            this.$message.success('更新成功')
            this.$set(row, 'shareStatus', shareStatus)
          }
        })
        .finally(() => {
          this.$set(row, 'loading', false)
        })
    },
    handlePlayVideo (row) {
      this.playVideo = true
      this.currentRows = row
    }
  }
}
</script>

<style lang="less">
.UgcVideo {
  .number {
    color: blue;
  }
  .swiper {
    position: relative;
  }
  .swiper-wrapper-content {
    width: 90%;
    height: 500px;
    --swiper-pagination-color: #FFCC37;
    margin: 0 auto;
    .swiper-container {
      border-radius: 6px;
      .swiper-wrapper {
        flex-wrap: nowrap;
      }
    }
    .image-slot {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 24px;
      background: #F4F4F4;
      color: #e6e6e6;
      &.image-slot-error {
        .error {
          width: 250px;
          height: 250px;
          background-image: url('~@/assets/img/xiaopangchetupianjiazaishibai-2.png');
          background-size: cover;
        }
    }
    }

    .tag-list {
      position: absolute;
      left: 20px;
      bottom: 20px;
      z-index: 100;
      display: flex;
      flex-wrap: wrap;
      margin: 40px 0 0 10px;
      padding: 0;
      li {
        font-size: 22px;
        height: 40px;
        line-height: 40px;
        background-color: rgba(0, 0, 0, .5);
        color: white;
        text-align: center;
        padding: 0 20px;
        margin-right: 20px;
        margin-bottom: 10px;
      }
    }
  }

  .video-body {
        width: 100%;
        background-color: #F5F7FA;
        border-radius: 8px;
        background-size: cover;
        overflow: hidden;
        position: relative;
        &:hover {
          .cover {
            display: flex;
          }
        }
        .image-slot {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
        .cover {
          display: none;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          color: white;
          border-radius: 8px;

          .video-play {
            color: white;
            font-size: 28px;
            cursor: pointer;
          }
        }
      }
}
</style>
